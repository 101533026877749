<template>
  <td v-tooltip="title">
    <!-- <vx-tooltip :text="title" position="left"> -->
    <feather-icon
      :icon="icon"
      class="cursor-pointer text-white pt-2"
      style="width:20px !important"
      @click="MAXIMIZE_CHAT_CONTAINER"
    ></feather-icon>
    <!-- </vx-tooltip> -->
  </td>
  <!-- :svgClasses="[{'text-warning fill-current stroke-current': isPinnedLocal}, 'w-6', 'h-6']" -->
</template>
<script>
import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    icon() {
      return this.maximizeChatContainer ? 'Minimize2Icon' : 'Maximize2Icon'
    },
    title() {
      return this.maximizeChatContainer
        ? this.lang.attentionRequests.navbar.maximize.off[this.languageSelected]
        : this.lang.attentionRequests.navbar.maximize.on[this.languageSelected]
    },
    ...mapState(['maximizeChatContainer', 'lang', 'languageSelected'])
  },
  methods: {
    ...mapMutations(['MAXIMIZE_CHAT_CONTAINER'])
  }
}
</script>
