<template>
  <div>
    <table>
      <tr>
        <!-- <td is="SwitchBot" :transfer="transfer"></td>-->
        <td style="visibility: hidden;">
          <RockerSwitch
            :labelOn="'🤖'"
            :labelOff="'👨‍💻'"
            :size="0.7"
            :toggle="true"
          />
        </td>
        <td is="Takeovers" v-bind="{ userId }"></td>
        <td is="ChangeView" v-if="isAuthAux('konecta.all_chats.view_in_attention_panel', true)" @transfer="transfer = new Date()"></td>
        <td is="CopyView" v-bind="{ baseUrl: attentionRequestUrl }"></td>
        <td is="Export" :bot="{ _id: 123 }"></td>
        <td is="Maximize"></td>
      </tr>
    </table>
  </div>
</template>
<script>
import Maximize from './Maximize'
// import SwitchBot from './SwitchBot'
// import Transfer from './Transfer'
import Export from './Export'
import ChangeView from './ChangeView'

import { mapState } from 'vuex'

import RockerSwitch from 'vue-rocker-switch'
import 'vue-rocker-switch/dist/vue-rocker-switch.css'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  components: {
    Maximize,
    // SwitchBot,
    // Transfer,
    Export,
    ChangeView,
    RockerSwitch,
    CopyView: () =>
      import('../../../attention-requests/chat/chat-navbar/CopyView.vue'),
    Takeovers: () =>
      import('../../../attention-requests/chat/chat-navbar/Takeovers.vue')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      transfer: ''
    }
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['activeChatUser']),
    attentionRequestUrl() {
      const isAppUrl = window.location.href.includes('/app/')
      const appUrl = isAppUrl ? '/app' : ''
      return `${window.location.origin}${appUrl}/#/attention-requests`
    }
  }
}
</script>
<style scoped>
td {
  padding: 0 10px;
}
</style>
