<template>
  <td v-tooltip="lang.attentionRequests.navbar.changeView[languageSelected]">
    <!-- <vx-tooltip :text="'Ver en panel de atención'" position="left"> -->
    <feather-icon
      @click="changeView"
      icon="EyeIcon"
      class="cursor-pointer text-white pt-2"
      style="width: 20px !important"
    ></feather-icon>
    <!-- </vx-tooltip> -->
  </td>
</template>
<script>
import konecta from '@/services/konecta'

import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['activeChatUser', 'bot', 'lang', 'languageSelected'])
  },
  methods: {
    changeView() {
      let routeData = this.$router.resolve({
        path: '/attention-requests',
        query: {
          bot: this.activeChatUser.bot,
          chat: this.activeChatUser._id
        }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({
      //   path: '/attention-requests',
      //   query: {
      //     bot: this.activeChatUser.bot,
      //     chat: this.activeChatUser._id
      //   }
      // })
    },
    transfer() {
      // const session = localStorage.getItem('session')
      // const token = JSON.parse(session).token
      // this.$socket.client.emit('join', {
      //   type: 'control',
      //   // bot: this.bot._id
      //   token,
      //   service: this.bot.service
      // })
      setTimeout(() => {
        konecta
          .post('/takeover/transfer-takeover', {
            bot: this.activeChatUser.bot,
            senderId: this.activeChatUser.sender
          })
          .then(() => {
            this.UPDATE_ACTIVE_CHAT = null
            this.$emit('transfer')
          })
      }, 100)
    },
    ...mapMutations(['UPDATE_ACTIVE_CHAT'])
  },
  watch: {}
}
</script>
