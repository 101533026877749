<template>
  <td v-tooltip="lang.attentionRequests.navbar.export[languageSelected]">
    <!-- <vx-tooltip
      :text="lang.attentionRequests.navbar.export[languageSelected]"
      position="left"
    >-->
    <a>
      <feather-icon
        @click="exportChat"
        icon="DownloadCloudIcon"
        class="cursor-pointer text-white pt-2"
        style="width:20px !important"
      ></feather-icon>
    </a>
    <!-- </vx-tooltip> -->
  </td>
</template>
<script>
import konecta from '@/services/konecta'

import { mapState } from 'vuex'
export default {
  data() {
    return {
      toggleBot: false
    }
  },
  computed: {
    ...mapState(['activeChatUser', 'lang', 'languageSelected'])
  },
  methods: {
    exportChat() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      this.$vs.loading({
        container: '#chat-app'
      })
      konecta
        .get(`/export/chat/${this.activeChatUser._id}`)
        .then(response => {
          let filename = 'konecta_export_'
          filename += (this.activeChatUser.fullname || 'user').replace(
            / /g,
            '_'
          )
          filename += '_'
          filename += this.activeChatUser.botName.replace(/ /g, '_')
          filename += '_'
          filename += new Date().toISOString()

          var BOM = '\uFEFF'
          var csvData = BOM + response.data

          var blob = new Blob([csvData], {
            type: 'text/plain; encoding=utf-8'
          })

          saveData(blob, `${filename}.csv`)
          this.$vs.loading.close('#chat-app > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#chat-app > .con-vs-loading')
          this.$vs.notify({
            title: 'Oops!',
            text: 'Parece que ocurrió un error',
            color: 'danger'
          })
        })
    }
  }
}
</script>
